import React, { Component, createRef } from "react";
import Dropzone from "react-dropzone";
import AddEditProductCategory from "./AddEditProductCategory";
import AddEditProductBrand from "./AddEditProductBrand";
import { withRouter } from "react-router-dom";
import * as actionTypes from "../../actionTypes";
import AuditTrail from "../AuditTrail";
import * as constants from "../../Constatnts";
import Switch from "react-switch";
import ReactTooltip from "react-tooltip";
import Input from "../2/Controls/Input";
import { getUOMs } from "../../CommonFunctions";
import Page from "../2/Controls/Page";
import PageSection from "../2/Controls/PageSection";
import Window from "../2/Controls/Window";
import { searchPincodeGlobalCatalog } from "../../services/api/PincodeAPI";
import GCLookup from "../Pincode/GCLookUp";
import PincodeAPIlogs from "../Pincode/PincodeAPILogs";

class ProductPincode extends Component {
  constructor() {
    super();
    this.state = {
      productTypes: [
        { value: 1, text: "Goods for sale" },
        { value: 2, text: "Goods not for sale" },
        // { value: 3, text: "Services" },
        // { value: 4, text: "Wallet Recharge" },
        // { value: 5, text: "Package" },
      ],
      gcProductName: "",
      GCIssue: "",
      GCIssueRemarks: "",
      showRemoveBarcodeButton: false,
      gc: false,
      pincodeMerchant: false,
      uoms: [],
      productAutoGenerateSKU: false,
      OMNIEnabled: false,
      quickAdd: true,
      sku: "",
      barCode: "",
      name: "",
      productTypeID: 1,
      productType: "Goods for sale",
      productTypeError: "",
      description: "",
      category: "",
      categoryID: "",
      subCategory: "",
      subCategoryID: "",
      brand: "",
      brandID: "",
      tags: "",
      images: [],
      compareAtPrice: "",
      supplyPrice: 0,
      markup: 0,
      retailPrice: 0,
      MRP: 0,
      MSP: 0,
      repackProduce: false,
      allowDiscount: true,
      allowPriceEdit: true,
      allowTaxChange: true,
      chargeTaxOnPoduct: false,
      taxInclusive: false,
      taxInclusivePurchase: false,
      priceBasedTax: false,
      walletTopUpSameAsRetailPrice: true,
      walletTopUpAmount: 0,
      walletTopUpError: "",
      taxID: "",
      taxGroup: "",
      taxPriceRange: null,
      initialTaxPriceRange: [
        {
          FromPrice: 0,
          ToPrice: 0,
          TaxID: "",
          TaxGroup: "",
          FromPriceErrMsg: "",
          ToPriceErrMsg: "",
          TaxErrMsg: "",
        },
        {
          FromPrice: 0,
          ToPrice: 0,
          TaxID: "",
          TaxGroup: "",
          FromPriceErrMsg: "",
          ToPriceErrMsg: "",
          TaxErrMsg: "",
        },
      ],
      HSNSACCode: "",
      categoryList: [],
      subCategoryList: [],
      brandList: [],
      tagList: [],
      isShowingModalAddCategory: false,
      firstLoad: false,
      isShowingModalAddBrand: false,
      isShowingModalAddSubCategory: false,
      nameError: "",
      categoryError: false,
      priceError: "",
      supplyPriceError: "",
      markupError: "",
      mrpError: "",
      skuError: "",
      categoryOrSubCategory: "",
      saving: false,
      editWalletTopUpAmount: false,
      accessibleToSelectedStores: false,
      storeSpecificPricing: false,
      storeSpecificPrices: [],
      storeRetailPrice: 0,
      storeSupplyPrice: 0,
      storeMarkUp: 0,
      storeMRP: 0,
      storeMSP: 0,
      storePriceStore: "",
      storePriceStoreID: "",
      storePriceStoreError: "",
      stores: [], //stores accessible to logged in user,
      storeWalletTopUpAmount: 0,
      storeWalletTopUpSameAsRetailPrice: true,
      storeEditWalletTopUpAmount: false,
      storeSpecificPricesError: "",
      packageServicesError: "",
      discountAmount: 0,
      discountPercentage: 0,
      storeDiscountAmount: 0,
      storeDiscountPercentage: 0,
      minQty: 0,
      maxQty: 0,
      reorderQty: 0,
      inventoryType: "FIFO",
      uniqueLabel: "",
      uom: "",
      searchMode: "",
      //Package Related
      services: [],
      service: "",
      serviceID: "",
      serviceDuration: 0,
      packageServices: [],
      serviceError: "",
      validityInDays: "",
      validityInDaysError: "",
      qtyPrompt: false,
      associateMembership: false,
      membershipID: "",
      membership: "",
      membershipError: "",
      mobile: false,
      storeSpecificQtys: [],
      notifyCustomerInDays: 0,
      notifyCustomerInDaysError: "",
      enableAlerts: false,
      smsTemplateID: "",
      smsTemplateError: "",
      smsTemplates: [],
      customField1: "",
      customField2: "",
      customField3: "",
      customField4: "",
      customField5: "",
      customField6: "",
      selectedAddress: null,
      selectedAddressIndex: -1,
      customField1DisplayName: "Custom Field 1",
      customField2DisplayName: "Custom Field 2",
      customField3DisplayName: "Custom Field 3",
      customField4DisplayName: "Custom Field 4",
      customField5DisplayName: "Custom Field 5",
      customField6DisplayName: "Custom Field 6",
      favourite: false,
      bom: false,
      bomManual: false,
      canBeUsedAsConsumable: false,
      batchTrackingBatchNoRequired: false,
      batchTrackingAutoBatchNoRequired: false,
      batchTrackingMRPRequired: false,
      batchTrackingMfgDateRequired: false,
      batchTrackingExpDateRequired: false,
      batchTrackingDateFormat: "mm/yy",
      inventoryTypeOptions: [
        { value: "FIFO" },
        // { value: "Unique No" },
        // { value: "Variants" },
        // { value: "Batch Tracking" },
      ],
      //UOM related
      secondaryUOMs: [],
      secondaryUOM: "",
      secondaryUOMError: "",
      conversion: 0,
      masterUOMs: [],
      salesUOM: "",
      pruchaseUOM: "",
      //Variant related
      attributes: [],
      attribute: "",
      attributeValue: "",
      attributeValues: [],
      selectedAttribute: null,
      variants: [],
      customSKU: "",
      autogenerateSKU: false,
      variantsError: "",
      // customSKUError:'',
      variantSKUError: "",
      showAsAdditionalCharges: false,
      packagingCharges: 0,
      outOfStock: false,
      longDescription: "",
      sequenceNo: "",
      maxOrderQty: "",
      pincodeProductID: "",
      IsDeleted: false,
    };
    this.onDrop = this.onDrop.bind(this);
    this.onRemoveImage = this.onRemoveImage.bind(this);
    this.onCategoryChange = this.onCategoryChange.bind(this);
    this.onProductTypeChange = this.onProductTypeChange.bind(this);
    this.onSubCategoryChange = this.onSubCategoryChange.bind(this);
    this.onBrandChange = this.onBrandChange.bind(this);
    this.onSaveProduct = this.onSaveProduct.bind(this);
    this.onTaxChange = this.onTaxChange.bind(this);
    this.loadProductBrands = this.loadProductBrands.bind(this);
    this.onFromPriceChange = this.onFromPriceChange.bind(this);
    this.onToPriceChange = this.onToPriceChange.bind(this);
    this.onPriceBasedTaxChange = this.onPriceBasedTaxChange.bind(this);
    this.onCancel = this.onCancel.bind(this);
    //Package Related
    this.formControls = this.formControls.bind(this);
    this.onHeaderBackClick = this.onHeaderBackClick.bind(this);
    //Variant Related
    this.renderAuditTrail = this.renderAuditTrail.bind(this);
    this.onBarCodeChange = this.onBarCodeChange.bind(this);
    this.onSaveNewMRP = this.onSaveNewMRP.bind(this);
  }

  onHeaderBackClick() {
    if (this.props.calledFromInvoice) {
      //console.log('Called fomm invoice ')
      this.props.onClose();
    } else if (this.props.isMobileApp()) {
      this.props.history.push({ pathname: "/home/catalogue/products" });
    } else {
      this.props.history.push("/home/product/pincode");
    }
  }

  async componentDidMount() {
    // console.log("searchBarcode", this.props.searchBarcode);
    let uoms = getUOMs();
    let productAutoGenerateSKU = false;
    let sku = "";

    if (
      this.props.registration.registration.hasOwnProperty("AutoGenerateSKU") &&
      this.props.registration.registration.AutoGenerateSKU
    ) {
      sku = "Auto generate";
      productAutoGenerateSKU = this.props.registration.registration
        .AutoGenerateSKU;
    }
    let stores = this.props.config.Stores;

    this.setState({
      uoms: uoms,
      stores: stores,
      productAutoGenerateSKU: productAutoGenerateSKU,
      sku: sku,
    });

    // console.log("this.props.key", this.props.selectedProductID);
    const key = this.props.match.params.key
      ? this.props.match.params.key
      : this.props.selectedProductID
      ? this.props.selectedProductID
      : "";
    if (key) {
      //existing product
      this.props.getProductDetails(key).then((product) => {
        //    console.log('product',product)
        let storeSpecificQtys = product.hasOwnProperty("StoreSpecificQtys")
          ? product.StoreSpecificQtys
          : [];
        if (storeSpecificQtys.length === 0) {
          for (let index = 0; index < stores.length; index++) {
            const obj = stores[index];
            let store = {
              StoreID: obj.key,
              StoreName: obj.StoreName,
              MinQty: 0,
              MaxQty: 0,
              ReorderQty: 0,
            };
            storeSpecificQtys.push(store);
          }
        } else {
          for (let index = 0; index < stores.length; index++) {
            const obj = stores[index];
            let foundStore = storeSpecificQtys.filter(
              (i) => i.StoreID === obj.key
            );
            if (foundStore.length === 0) {
              let store = {
                StoreID: obj.key,
                StoreName: obj.StoreName,
                MinQty: 0,
                MaxQty: 0,
                ReorderQty: 0,
              };
              storeSpecificQtys.push(store);
            }
          }
        }

        let masterUOMs = [];
        if (product.hasOwnProperty("UOM") && product.UOM !== "") {
          masterUOMs.push({
            value: product.UOM,
            text: this.state.uoms.filter((f) => f.value === product.UOM)[0]
              .text,
          });
        }
        if (
          product.hasOwnProperty("SecondaryUOMs") &&
          product.SecondaryUOMs.length > 0
        ) {
          for (let su = 0; su < product.SecondaryUOMs.length; su++) {
            const obj1 = product.SecondaryUOMs[su];
            masterUOMs.push({
              value: obj1.SecondaryUOMValue,
              text: obj1.SecondaryUOM,
            });
          }
        }

        let variants = product.hasOwnProperty("Variants")
          ? product.Variants
          : [];
        for (let i = 0; i < variants.length; i++) {
          const variant = variants[i];
          if (!variant.hasOwnProperty("IsDeleted")) {
            variant.IsDeleted = false;
          }
        }

        let supplyPrice = product.SupplyPrice;
        let markup = product.Markup;
        let mrp = product.Markup;
        let retailPrice = product.RetailPrice;
        let msp = product.hasOwnProperty("MSP") ? product.MSP : 0;
        let discountAmount = product.hasOwnProperty("DiscountAmount")
          ? product.DiscountAmount
          : 0;
        let discountPercentage = product.hasOwnProperty("DiscountPercentage")
          ? product.DiscountPercentage
          : 0;

        if (
          product.StoreSpecificPrices &&
          product.StoreSpecificPrices.length > 0
        ) {
          supplyPrice = product.StoreSpecificPrices[0].SupplyPrice;
          markup = product.StoreSpecificPrices[0].Markup;
          mrp = product.StoreSpecificPrices[0].MRP;
          retailPrice = product.StoreSpecificPrices[0].RetailPrice;
          msp = product.StoreSpecificPrices[0].hasOwnProperty("MSP")
            ? product.StoreSpecificPrices[0].MSP
            : 0;
          discountAmount = product.StoreSpecificPrices[0].hasOwnProperty(
            "DiscountAmount"
          )
            ? product.StoreSpecificPrices[0].DiscountAmount
            : 0;
          discountPercentage = product.StoreSpecificPrices[0].hasOwnProperty(
            "DiscountPercentage"
          )
            ? product.StoreSpecificPrices[0].DiscountPercentage
            : 0;
        }

        let gcProductName = "";
        let gc = false;
        if (
          product.hasOwnProperty("CustomField2") &&
          product.CustomField2 &&
          product.CustomField2 === "GC"
        ) {
          gc = true;
          gcProductName = product.Name;
        }
        let sku = product.SKU;
        if (this.props.newMRP) {
          sku = sku + "-1";
          mrp = "";
          retailPrice = "";
        }
        this.setState({
          IsDeleted: product.IsDeleted,
          pincodeProductID: product.hasOwnProperty("PincodeProductID")
            ? product.PincodeProductID
            : "",
          key: key,
          gcProductName: gcProductName,
          product: product,
          gc: gc,
          quickAdd: false,
          sku: sku,
          barCode: product.BarCode,
          name: product.Name,
          productID: product.ProductID,
          productType: product.ProductType,
          productTypeID: product.ProductTypeID,
          description: product.Description,
          category: product.Category,
          categoryID: product.CategoryID,
          subCategory: product.SubCategory,
          subCategoryID: product.SubCategoryID,
          brand: product.Brand,
          brandID: product.BrandID,
          tags: product.tags,
          supplyPrice: supplyPrice, //product.SupplyPrice,
          markup: markup, // product.Markup,
          retailPrice: retailPrice, // product.RetailPrice,
          MRP: mrp, /// product.MRP,
          MSP: msp, // product.hasOwnProperty("MSP") ? product.MSP : 0,
          allowDiscount: product.hasOwnProperty("AllowDiscount")
            ? product.AllowDiscount
            : true,
          allowPriceEdit: product.hasOwnProperty("AllowPriceEdit")
            ? product.AllowPriceEdit
            : true,
          allowTaxChange: product.hasOwnProperty("AllowTaxChange")
            ? product.AllowTaxChange
            : true,
          priceBasedTax: product.PriceBasedTax,
          walletTopUpSameAsRetailPrice: product.hasOwnProperty(
            "WalletTopUpSameAsRetailPrice"
          )
            ? product.WalletTopUpSameAsRetailPrice
            : true,
          walletTopUpAmount: product.hasOwnProperty("WalletTopUpAmount")
            ? product.WalletTopUpAmount
            : 0,
          chargeTaxOnPoduct: product.ChargeTaxOnProduct,
          taxInclusive: product.TaxInclusive,
          taxInclusivePurchase: product.hasOwnProperty("TaxInclusivePurchase")
            ? product.TaxInclusivePurchase
            : false,
          HSNSACCode: product.HSNSACCode,
          taxID: product.TaxID,
          taxGroup: product.TaxGroup,
          taxPriceRange: product.TaxPriceRange,
          images: product.Images,
          editWalletTopUpAmount: product.hasOwnProperty("EditWalletTopUpAmount")
            ? product.EditWalletTopUpAmount
            : false,
          accessibleToSelectedStores: product.hasOwnProperty(
            "AccessibleToSelectedStores"
          )
            ? product.AccessibleToSelectedStores
            : false,
          storeSpecificPricing: product.hasOwnProperty("StoreSpecificPricing")
            ? product.StoreSpecificPricing
            : false,
          storeSpecificPrices: product.hasOwnProperty("StoreSpecificPrices")
            ? product.StoreSpecificPrices
            : [],
          discountAmount: discountAmount,
          discountPercentage: discountPercentage,
          minQty: product.hasOwnProperty("MinQty") ? product.MinQty : 0,
          maxQty: product.hasOwnProperty("MaxQty") ? product.MaxQty : 0,
          reorderQty: product.hasOwnProperty("ReorderQty")
            ? product.ReorderQty
            : 0,
          inventoryType: product.hasOwnProperty("InventoryType")
            ? product.InventoryType
            : "FIFO",
          uom: product.hasOwnProperty("UOM") ? product.UOM : "",
          searchMode: product.hasOwnProperty("SearchMode")
            ? product.SearchMode
            : "All",
          uniqueLabel: product.hasOwnProperty("UniqueLabel")
            ? product.UniqueLabel
            : "",
          packageServices: product.hasOwnProperty("PackageServices")
            ? product.PackageServices
            : [],
          validityInDays: product.hasOwnProperty("ValidityInDays")
            ? product.ValidityInDays.toString()
            : "",
          repackProduce: product.hasOwnProperty("RepackProduce")
            ? product.RepackProduce
            : false,
          qtyPrompt: product.hasOwnProperty("QtyPrompt")
            ? product.QtyPrompt
            : false,
          associateMembership: product.hasOwnProperty("AssociateMembership")
            ? product.AssociateMembership
            : false,
          membership: product.hasOwnProperty("Membership")
            ? product.Membership
            : "",
          membershipID: product.hasOwnProperty("MembershipID")
            ? product.MembershipID
            : "",
          storeSpecificQtys: storeSpecificQtys,
          notifyCustomerInDays: product.hasOwnProperty("NotifyCustomerInDays")
            ? product.NotifyCustomerInDays
            : 0,
          enableAlerts: product.hasOwnProperty("EnableAlerts")
            ? product.EnableAlerts
            : false,
          smsTemplateID: product.hasOwnProperty("SMSTemplateID")
            ? product.SMSTemplateID
            : "",
          customField1: product.hasOwnProperty("CustomField1")
            ? product.CustomField1
            : "",
          customField2: product.hasOwnProperty("CustomField2")
            ? product.CustomField2
            : "",
          customField3: product.hasOwnProperty("CustomField3")
            ? product.CustomField3
            : "",
          customField4: product.hasOwnProperty("CustomField4")
            ? product.CustomField4
            : "",
          customField5: product.hasOwnProperty("CustomField5")
            ? product.CustomField5
            : "",
          customField6: product.hasOwnProperty("CustomField6")
            ? product.CustomField6
            : "",
          favourite: product.hasOwnProperty("Favourite")
            ? product.Favourite
            : false,
          serviceDuration: product.hasOwnProperty("ServiceDuration")
            ? product.ServiceDuration
            : 0,
          bom: product.hasOwnProperty("BOM") ? product.BOM : false,
          bomManual: product.hasOwnProperty("BOMManual")
            ? product.BOMManual
            : false,
          canBeUsedAsConsumable: product.hasOwnProperty("CanBeUsedAsConsumable")
            ? product.CanBeUsedAsConsumable
            : false,
          batchTrackingBatchNoRequired: product.hasOwnProperty(
            "BatchTrackingBatchNoRequired"
          )
            ? product.BatchTrackingBatchNoRequired
            : false,
          batchTrackingAutoBatchNoRequired: product.hasOwnProperty(
            "BatchTrackingAutoBatchNoRequired"
          )
            ? product.BatchTrackingAutoBatchNoRequired
            : false,
          batchTrackingMRPRequired: product.hasOwnProperty(
            "BatchTrackingMRPRequired"
          )
            ? product.BatchTrackingMRPRequired
            : false,
          batchTrackingMfgDateRequired: product.hasOwnProperty(
            "BatchTrackingMfgDateRequired"
          )
            ? product.BatchTrackingMfgDateRequired
            : false,
          batchTrackingExpDateRequired: product.hasOwnProperty(
            "BatchTrackingExpDateRequired"
          )
            ? product.BatchTrackingExpDateRequired
            : false,
          batchTrackingDateFormat: product.hasOwnProperty(
            "BatchTrackingDateFormat"
          )
            ? product.BatchTrackingDateFormat
            : false,
          OMNIEnabled: product.hasOwnProperty("OMNIEnabled")
            ? product.OMNIEnabled
            : false,
          outOfStock: product.hasOwnProperty("OutOfStock")
            ? product.OutOfStock
            : false,
          //UOM
          secondaryUOMs: product.hasOwnProperty("SecondaryUOMs")
            ? product.SecondaryUOMs
            : [],
          masterUOMs: masterUOMs,
          salesUOM: product.hasOwnProperty("SalesUOM") ? product.SalesUOM : "",
          pruchaseUOM: product.hasOwnProperty("PurchaseUOM")
            ? product.PurchaseUOM
            : "",
          //Variant
          variants: variants, //product.hasOwnProperty('Variants') ? product.Variants : [],
          customSKU: product.hasOwnProperty("CustomSKU")
            ? product.CustomSKU
            : "",
          autogenerateSKU: product.hasOwnProperty("AutoGenerateSKU")
            ? product.AutoGenerateSKU
            : false,
          attributes: product.hasOwnProperty("Attributes")
            ? product.Attributes
            : [],
          showAsAdditionalCharges: product.hasOwnProperty(
            "ShowAsAdditionalCharges"
          )
            ? product.ShowAsAdditionalCharges
            : false,
          packagingCharges: product.hasOwnProperty("PackagingCharges")
            ? product.PackagingCharges
            : 0,
          longDescription: product.hasOwnProperty("LongDescription")
            ? product.LongDescription
            : "",
          sequenceNo: product.hasOwnProperty("SequenceNo")
            ? product.SequenceNo
            : "",
          maxOrderQty: product.hasOwnProperty("MaxOrderQty")
            ? product.MaxOrderQty
            : "",
        });
      });
    } else {
      let storeSpecificQtys = [];
      for (let index = 0; index < stores.length; index++) {
        const obj = stores[index];
        let store = {
          StoreID: obj.key,
          StoreName: obj.StoreName,
          StoreCode: obj.StoreCode,
          MinQty: 0,
          MaxQty: 0,
          ReorderQty: 0,
          // InStockQty: 0,
        };
        storeSpecificQtys.push(store);
      }
      this.setState({ storeSpecificQtys: storeSpecificQtys });

      // searchBarcode
      if (this.props.searchBarcode && this.props.searchBarcode !== "") {
        this.setState({ saving: true });
        let result1 = await searchPincodeGlobalCatalog(
          this.props.searchBarcode,
          ""
        );
        // console.log("result", JSON.stringify(result));
        let result = result1.filter(
          (f) => f.Code.trim() === this.props.searchBarcode.trim()
        );

        if (result && result.length === 1) {
          let productName =
            result[0].Name + " " + result[0].Quantity + " " + result[0].UOM;
          this.setState({
            gcProductName: productName,
            barCode: result[0].Code,
            name: productName,
            MRP: result[0].MRP,
            customField1: this.props.searchBarcode,
            customField2: "GC",
            customField3: result[0].Type,
            categoryID: "",
            category: result[0].Category,
            subCategoryID: "",
            subCategory: result[0].Subcategory,
            brandID: "",
            brand: result[0].Brand,
            gc: true,
            showRemoveBarcodeButton: true,
            images: [
              {
                ImageName: result[0].Code,
                ImagePath: result[0].Image,
                ImageSize: 0,
              },
            ],
          });
        } else if (result && result.length > 1) {
          this.setState({
            barCode: this.props.searchBarcode,
            showGCLookup: true,
            showGCLookupText: this.props.searchBarcode,
            showGCLookupType: "2",
            customField2: "GCS",
            barCode: this.props.searchBarcode,
          });
        } else {
          this.setState({ barCode: this.props.searchBarcode });
        }
        this.setState({ saving: false });
      }
    }
  }

  onDrop(acceptedFiles, rejectedFiles) {
    if (acceptedFiles.length > 0) {
      if (acceptedFiles.length + this.state.images.length > 4) {
        alert("Max no images uploaded");
        return;
      }
      if (acceptedFiles[0].size / 1024 > 500) {
        alert("File size should not be more than 500 KB");
        return;
      }
      this.setState({ imageLoading: true });

      let fileName = +new Date().getTime() + acceptedFiles[0].name;
      this.props
        .uploadFileToServer(
          acceptedFiles[0],
          this.props.registration.registration.StorageFolder + "/Product",
          fileName
        )
        .then((downloadURL) => {
          //update product with images url
          let bucket = "posible.in/";
          let folder =
            this.props.registration.registration.StorageFolder + "/Product/";
          let url =
            "https://storage.googleapis.com/" + bucket + folder + fileName;
          let image = {
            ImageName: fileName,
            ImageSize: acceptedFiles[0].size,
            ImagePath: url,
          };
          // {ImagePath:imagePath, ImageName: imageName, ImageSize: imageSize }
          let imagesToBeUploaded = this.state.images;
          imagesToBeUploaded.push(image);
          this.setState({ images: imagesToBeUploaded });
          this.setState({ imageLoading: false });
        });
    } else {
      alert("No file selected");
    }
  }

  onRemoveImage(index) {
    let array = [...this.state.images];
    array.splice(index, 1);
    this.setState({ images: array });
  }

  onCategoryChange = (e) => {
    let categoryID = e.target.value;
    let category = e.target.options[e.target.selectedIndex].text;
    if (categoryID !== "") {
      this.setState({ categoryError: false });
    }
    this.setState({
      category: category,
      categoryID: categoryID,
      subCategory: "",
      subCategoryID: "",
    });
    //this.setState({subCategoryList: this.props.product.Categories[categoryIndex].SubCategories})
  };

  onProductTypeChange = (value) => {
    let productTypeID = value;
    let productType =
      Number(value) > 0
        ? this.state.productTypes.filter(
            (f) => Number(f.value) === Number(value)
          )[0].text
        : "";
    if (productTypeID !== "") {
      this.setState({ productTypeError: "" });
    }
    this.setState({
      productTypeID: Number(productTypeID),
      productType: productType,
      uom: "",
      secondaryUOMs: [],
      masterUOMs: [],
      conversion: 0,
      uniqueLabel: "",
      pruchaseUOM: "",
      salesUOM: "",
      secondaryUOMValue: "",
      secondaryUOMError: "",
      conversionError: "",
      attributes: [],
      attribute: "",
      attributeValue: "",
      attributeValues: [],
      selectedAttribute: null,
      variants: [],
      autogenerateSKU: false,
      batchTrackingBatchNoRequired: false,
      batchTrackingMfgDateRequired: false,
      batchTrackingMRPRequired: false,
      batchTrackingExpDateRequired: false,
    });
  };

  onSubCategoryChange = (e) => {
    let subCategoryID = e.target.value;
    let subCategory = e.target.options[e.target.selectedIndex].text;
    this.setState({ subCategory: subCategory, subCategoryID: subCategoryID });
  };

  onBrandChange(e) {
    let brandID = e.target.value;
    let brand = e.target.options[e.target.selectedIndex].text;
    this.setState({ brand: brand, brandID: brandID });
    //console.log('onBrandChange',e.target.value )
  }

  onTaxChange(e) {
    let taxID = e.target.value;
    let tax = e.target.options[e.target.selectedIndex].text;
    this.setState({ taxGroup: tax, taxID: taxID });
    //console.log('onTaxChange',e.target.value )
  }

  onCancel() {
    if (this.props.calledFromInvoice) {
      // console.log('Called from invoice ')
      this.props.onClose();
    } else {
      this.props.history.push("/home/product/pincode");
    }
  }

  async onSaveProduct() {
    // console.log('save called')
    this.setState({ saving: true });
    let nameError = "";
    let categoryError = false;
    let priceError = "";
    let skuError = "";
    let priceBasedTaxError = false;
    let productTypeError = "";
    let walletTopUpError = "";
    let supplyPriceError = "";
    let markupError = "";
    let storeSpecificPricesError = "";
    let packageServicesError = "";
    let validityInDaysError = "";
    let membershipError = "";
    let notifyCustomerInDaysError = "";
    let smsTemplateError = "";
    let variantsError = "";
    let variantSKUError = "";
    let mrpError = "";
    let storeSpecificPrices = []; //this.state.storeSpecificPrices;
    let storeSpecificPricesLineItemErrors = false;
    let barcodeError = "";

    if (this.state.name === "") {
      nameError = "Please provide product name";
    }
    if (
      this.state.retailPrice.toString().trim() === "" ||
      this.state.retailPrice === 0
    ) {
      priceError = "Please provide retail price";
    } else if (this.state.retailPrice < 0) {
      priceError = "Retail price cannot be negative";
    }
    if (this.state.MRP.toString().trim() === "" || this.state.MRP === 0) {
      mrpError = "Please provide MRP";
    } else if (this.state.MRP < 0) {
      mrpError = "MRP cannot be negative";
    }
    if (
      priceError === "" &&
      mrpError === "" &&
      Number(this.state.retailPrice) > Number(this.state.MRP)
    ) {
      priceError = "Retail price more than MRP";
    }
    if (this.state.supplyPrice < 0) {
      supplyPriceError = "Supply price cannot be negative";
    }
    if (this.state.category === "") {
      categoryError = true;
    }
    if (this.state.barCode.toString().trim() === "") {
      barcodeError = "Please provide barcode";
    }
    if (this.state.sku.toString().trim() === "") {
      skuError = "Please provide SKU";
    }

    //check SKU already exits
    if (this.state.sku.trim() !== "") {
      let skuExists = await this.props.checkIfSKUExists(
        this.props.registration.registration.key,
        this.state.sku.trim(),
        this.state.key
      );
      if (skuExists) {
        skuError = "SKU already exists";
      }
    } //
    if (this.state.barCode !== "") {
      let barcodeMRPIsUnique = await this.props.checkIfBarcodeMRPIsUnique(
        this.props.registration.registration.key,
        this.state.barCode.trim(),
        this.state.MRP,
        this.state.key,
        this.state.pincodeProductID
      );
      if (!barcodeMRPIsUnique) {
        barcodeError = "Barcode with same MRP already exists";
      }
    }
    //check if barcode and MRP combination is unique

    if (
      barcodeError !== "" ||
      nameError.trim() !== "" ||
      mrpError !== "" ||
      priceError !== "" ||
      supplyPriceError !== "" ||
      markupError !== "" ||
      categoryError ||
      skuError !== "" ||
      priceBasedTaxError ||
      productTypeError !== "" ||
      walletTopUpError !== "" ||
      storeSpecificPricesError !== "" ||
      packageServicesError !== "" ||
      notifyCustomerInDaysError !== "" ||
      smsTemplateError != "" ||
      validityInDaysError !== "" ||
      membershipError !== "" ||
      variantsError !== "" ||
      variantSKUError !== "" ||
      storeSpecificPricesLineItemErrors
    ) {
      this.setState({
        barcodeError: barcodeError,
        nameError: nameError,
        priceError: priceError,
        supplyPriceError: supplyPriceError,
        markupError: markupError,
        categoryError: categoryError,
        skuError: skuError,
        productTypeError: productTypeError,
        walletTopUpError: walletTopUpError,
        storeSpecificPricesError: storeSpecificPricesError,
        packageServicesError: packageServicesError,
        validityInDaysError: validityInDaysError,
        membershipError: membershipError,
        notifyCustomerInDaysError: notifyCustomerInDaysError,
        smsTemplateError: smsTemplateError,
        mrpError: mrpError,
        variantsError: variantsError,
        variantSKUError: variantSKUError,
        storeSpecificPrices: storeSpecificPrices,
        saving: false,
      });
      return;
    }

    // const key = this.props.match.params.key;
    const key = this.state.key;

    // console.log('key', key)
    let sku = this.state.sku.trim();
    if (this.state.productAutoGenerateSKU && !key) {
      await this.props.getRegistration(
        this.props.registration.registration.key
      );
      sku = this.props.registration.registration.hasOwnProperty(
        "AutoGenerateSKUPrefix"
      )
        ? this.props.registration.registration.AutoGenerateSKUPrefix
        : "";
      // console.log('sku', sku)
      let no = this.props.registration.registration.hasOwnProperty(
        "AutoGenerateSKURunningNo"
      )
        ? this.props.registration.registration.AutoGenerateSKURunningNo
        : 1;
      if (
        this.props.registration.registration.hasOwnProperty(
          "AutoGenerateSKUPaddedZero"
        ) &&
        this.props.registration.registration.AutoGenerateSKUPaddedZero > 0
      ) {
        let padded = ("0000000000" + no).slice(
          -1 *
            Number(
              this.props.registration.registration.AutoGenerateSKUPaddedZero
            )
        );
        sku += padded;
      } else {
        sku += no;
      }
    }
    let longDescription = this.state.longDescription;

    let category = this.state.category.trim();
    let categoryID = this.state.categoryID;
    let subCategory = this.state.subCategory;
    let subCategoryID = this.state.subCategoryID;
    let brand = this.state.brand;
    let brandID = this.state.brandID;

    //check if category is found
    if (categoryID === "") {
      //check if category found in
      if (
        this.props.config.ProductCategories.filter(
          (e) => e.Name === category && !e.IsDeleted
        ).length > 0
      ) {
        categoryID = this.props.config.ProductCategories.filter(
          (e) => e.Name === category && !e.IsDeleted
        )[0].key;
      } else {
        //create new category
        let productCategory = {
          Parent: "",
          ParentID: "",
          Name: category,
          Sequence: 0,
          Color: "",
          Image: "",
          RegistrationID: this.props.user.user.RegistrationID,
          IsDeleted: false,
          BackEndUpdate: false,
          OMNIEnabled: false,
        };
        categoryID = await this.props.saveProductCategory("", productCategory);
      }
    }
    //check if sub category is found
    if (subCategoryID === "") {
      if (
        this.props.config.ProductSubCategories.filter(
          (e) =>
            e.ParentID === categoryID && !e.IsDeleted && e.Name === subCategory
        ).length > 0
      ) {
        subCategoryID = this.props.config.ProductSubCategories.filter(
          (e) =>
            e.ParentID === categoryID && !e.IsDeleted && e.Name === subCategory
        )[0].key;
      } else {
        //create new sub category
        let productCategory = {
          Parent: category,
          ParentID: categoryID,
          Name: subCategory,
          Sequence: 0,
          Color: "",
          Image: "",
          RegistrationID: this.props.user.user.RegistrationID,
          IsDeleted: false,
          BackEndUpdate: false,
          OMNIEnabled: false,
        };
        subCategoryID = await this.props.saveProductCategory(
          "",
          productCategory
        );
      }
    }
    if (brandID == "") {
      if (
        this.props.config.ProductBrands.filter(
          (e) => !e.IsDeleted && e.Brand === brand
        ).length > 0
      ) {
        brandID = this.props.config.ProductBrands.filter(
          (e) => !e.IsDeleted && e.Brand === brand
        )[0].key;
      } else {
        let productBrand = {
          Brand: brand,
          RegistrationID: this.props.user.user.RegistrationID,
          IsDeleted: false,
          OMNITag: "",
        };
        brandID = await this.props.saveProductBrand(productBrand, "");
      }
    }

    let name = this.state.name.trim();
    // console.log("name", name);

    if (this.state.gc && this.state.GCIssue === "") {
      name = this.state.gcProductName;
      // console.log("this.state.gcProductName", this.state.gcProductName);
    }

    let OMNIEnabled = this.state.gc;
    let outOfStock = false;
    let customField4 = this.state.customField4;
    let customField5 = this.state.customField5;
    if (this.state.GCIssue !== "") {
      customField4 = this.state.GCIssue;
      // OMNIEnabled = false;
      // outOfStock = true;
      customField5 = this.state.GCIssueRemarks;
    }
    this.props.config.Stores.map((m) => {
      storeSpecificPrices.push({
        StoreID: m.key,
        Store: m.StoreCode + " " + m.StoreName,
        SupplyPrice: this.state.supplyPrice,
        Markup: this.state.markup,
        RetailPrice: this.state.retailPrice,
        MRP: this.state.MRP,
        MSP: this.state.MSP,
        WalletTopUpSameAsRetailPrice: false,
        EditWalletTopUpAmount: false,
        DiscountAmount: this.state.discountAmount,
        DiscountPercentage: this.state.discountPercentage,
        OMNIEnabled: OMNIEnabled,
        OutOfStock: outOfStock,
      });
    });

    let product = {
      RegistrationID: this.props.user.user.RegistrationID,
      Name: name,
      ProductTypeID: this.state.productTypeID,
      ProductType: this.state.productType,
      Description: this.state.description,
      SKU: sku,
      BarCode: this.state.barCode,
      Category: category,
      CategoryID: categoryID,
      SubCategoryID: subCategoryID,
      SubCategory: subCategory,
      //Tags:this.state.tags,
      BrandID: this.state.brandID,
      Brand: this.state.brand,
      SupplyPrice: this.state.supplyPrice,
      Markup: this.state.markup,
      RetailPrice: this.state.retailPrice,
      MRP: this.state.MRP,
      MSP: this.state.MSP,
      WalletTopUpSameAsRetailPrice: this.state.walletTopUpSameAsRetailPrice,
      WalletTopUpAmount: this.state.walletTopUpSameAsRetailPrice
        ? this.state.retailPrice
        : this.state.walletTopUpAmount,
      AllowDiscount: this.state.allowDiscount,
      AllowPriceEdit: this.state.allowPriceEdit,
      AllowTaxChange: this.state.allowTaxChange,
      ChargeTaxOnProduct: this.state.chargeTaxOnPoduct,
      TaxInclusive: this.state.taxInclusive,
      TaxInclusivePurchase: this.state.taxInclusivePurchase,
      HSNSACCode: this.state.HSNSACCode,
      TaxID: this.state.taxID,
      TaxGroup: this.state.taxGroup,
      PriceBasedTax: this.state.priceBasedTax,
      TaxPriceRange: this.state.priceBasedTax ? this.state.taxPriceRange : [],
      Images: this.state.images,
      IsDeleted: false,
      EditWalletTopUpAmount: this.state.editWalletTopUpAmount,
      AccessibleToSelectedStores: this.state.accessibleToSelectedStores,
      StoreSpecificPricing: true, //this.state.storeSpecificPricing,
      StoreSpecificPrices: storeSpecificPrices, //this.state.storeSpecificPrices,
      DiscountAmount: this.state.discountAmount,
      DiscountPercentage: this.state.discountPercentage,
      MinQty: this.state.minQty,
      MaxQty: this.state.maxQty,
      ReorderQty: this.state.reorderQty,
      UniqueLabel: this.state.uniqueLabel,
      InventoryType: this.state.inventoryType,
      UOM: this.state.uom,
      SearchMode: this.state.searchMode,
      RepackProduce: this.state.repackProduce,
      //Package
      PackageServices: this.state.packageServices,
      ValidityInDays: Number(this.state.validityInDays),
      QtyPrompt: this.state.qtyPrompt,
      Membership: this.state.membership,
      MembershipID: this.state.membershipID,
      AssociateMembership: this.state.associateMembership,
      StoreSpecificQtys: this.state.storeSpecificQtys,
      EnableAlerts: this.state.enableAlerts,
      NotifyCustomerInDays: Number(this.state.notifyCustomerInDays),
      SMSTemplateID: this.state.smsTemplateID,
      CustomField1: this.state.customField1,
      CustomField2:
        this.state.customField2 === "" ? "NGC" : this.state.customField2,
      CustomField3: this.state.customField3,
      CustomField4: customField4,
      CustomField5: customField5,
      CustomField6: this.state.customField6,
      Favourite: this.state.favourite,
      ServiceDuration: this.state.serviceDuration,
      BOM: this.state.bom,
      BOMManual: this.state.bomManual,
      CanBeUsedAsConsumable: this.state.canBeUsedAsConsumable,
      BatchTrackingBatchNoRequired: this.state.batchTrackingBatchNoRequired,
      BatchTrackingAutoBatchNoRequired: this.state
        .batchTrackingAutoBatchNoRequired,
      BatchTrackingMRPRequired: this.state.batchTrackingMRPRequired,
      BatchTrackingMfgDateRequired: this.state.batchTrackingMfgDateRequired,
      BatchTrackingExpDateRequired: this.state.batchTrackingExpDateRequired,
      BatchTrackingDateFormat: this.state.batchTrackingDateFormat,
      OMNIEnabled: OMNIEnabled,
      OutOfStock: outOfStock,
      //UOMs
      SecondaryUOMs: this.state.secondaryUOMs,
      SalesUOM: this.state.salesUOM,
      PurchaseUOM: this.state.pruchaseUOM,
      //Variants
      Attributes: this.state.attributes,
      Variants: this.state.variants,
      // VariantsNew: this.state.variants,
      AutoGenerateSKU: this.state.autogenerateSKU,
      CustomSKU: this.state.sku.trim(),
      ShowAsAdditionalCharges: this.state.showAsAdditionalCharges,
      PackagingCharges: this.state.packagingCharges,
      LongDescription: longDescription,
      SequenceNo: this.state.sequenceNo,
      MaxOrderQty: this.state.maxOrderQty,
      PincodeProductID: this.state.pincodeProductID,
    };
    let omniTag = "",
      prodName = "",
      prodSKU = "";
    prodName = product.Name.replace(
      /[@!&\/\\#,+()$~%.'"`_:*?<>{}-]/g,
      ""
    ).replace(/[' ']/g, "_");
    prodSKU = product.SKU.replace(
      /[@!&\/\\#,+()$~%.'"`_:*?<>{}-]/g,
      ""
    ).replace(/[' ']/g, "_");
    omniTag = prodName + "_" + prodSKU;
    product.OMNITag = omniTag;
    console.log("product", product);
    if (key) {
      //update product details
      this.props.updateProduct(product, key).then(async () => {
        await this.props.pushProductToRedux({ ...product, key: key });
        await this.props.poupulateSaleSubCategories();
        await this.props.poupulateSaleCategories();
        this.props.getToastr("Product Saved Successfully");
        setTimeout(
          function() {
            this.setState({ saving: false });
            if (this.props.calledFromInvoice) {
              //console.log('Called fomm invoice ')
              this.props.onSave({ ...product, key: key });
            } else {
              this.props.history.push("/home/product/pincode");
            }
          }.bind(this),
          2000
        );
      });
    } else {
      //create new product
      // console.log('product',product);
      let key = await this.props.addNewProduct(product);
      await this.props.pushProductToRedux({ ...product, key: key });
      await this.props.poupulateSaleSubCategories();
      await this.props.poupulateSaleCategories();
      if (this.state.productAutoGenerateSKU) {
        let autoGenerateSKURunningNo =
          Number(
            this.props.registration.registration.AutoGenerateSKURunningNo
          ) + 1;
        await this.props.updateRegistrationGeneralDetails(
          this.props.registration.registration.key,
          { AutoGenerateSKURunningNo: autoGenerateSKURunningNo }
        );
        this.props.getRegistration(this.props.registration.registration.key);
      }
      // console.log('key',key)
      this.props.getToastr("Product Saved Successfully");
      setTimeout(
        function() {
          //Start the timer
          if (this.props.calledFromInvoice) {
            //console.log('Called fomm invoice ')
            this.props.onSave({ ...product, key: key });
          } else {
            this.props.history.push("/home/product/pincode");
          }
        }.bind(this),
        2000
      );
    }
  }

  async onSaveNewMRP() {
    this.setState({ saving: true });
    let skuError = "";
    let mrpError = "";
    let priceError = "";

    if (this.state.sku.trim() === "") {
      skuError = "Please provide SKU";
    }

    if (this.state.MRP === "") {
      mrpError = "Please provide MRP";
    }

    if (this.state.retailPrice === "") {
      priceError = "Please provide retail price";
    }

    if (skuError !== "" || mrpError !== "" || priceError !== "") {
      this.setState({
        skuError: skuError,
        mrpError: mrpError,
        priceError: priceError,
        saving: false,
      });
      return;
    }

    if (this.state.sku.trim() !== "") {
      let skuExists = await this.props.checkIfSKUExists(
        this.props.registration.registration.key,
        this.state.sku.trim(),
        ""
      );
      if (skuExists) {
        skuError = "SKU already exists";
      }
    }

    //check if barcode and MRP combination is unique
    let barcodeMRPIsUnique = await this.props.checkIfBarcodeMRPIsUnique(
      this.props.registration.registration.key,
      this.state.barCode.trim(),
      this.state.MRP,
      ""
    );
    if (!barcodeMRPIsUnique) {
      mrpError = "Barcode with same MRP already exists";
    }

    if (skuError !== "" || mrpError !== "" || priceError !== "") {
      this.setState({
        skuError: skuError,
        mrpError: mrpError,
        priceError: priceError,
        saving: false,
      });
      return;
    }

    let product = this.state.product;
    let sku = this.state.sku.trim();
    if (this.state.productAutoGenerateSKU) {
      await this.props.getRegistration(
        this.props.registration.registration.key
      );
      sku = this.props.registration.registration.hasOwnProperty(
        "AutoGenerateSKUPrefix"
      )
        ? this.props.registration.registration.AutoGenerateSKUPrefix
        : "";
      let no = this.props.registration.registration.hasOwnProperty(
        "AutoGenerateSKURunningNo"
      )
        ? this.props.registration.registration.AutoGenerateSKURunningNo
        : 1;
      if (
        this.props.registration.registration.hasOwnProperty(
          "AutoGenerateSKUPaddedZero"
        ) &&
        this.props.registration.registration.AutoGenerateSKUPaddedZero > 0
      ) {
        let padded = ("0000000000" + no).slice(
          -1 *
            Number(
              this.props.registration.registration.AutoGenerateSKUPaddedZero
            )
        );
        sku += padded;
      } else {
        sku += no;
      }
    }
    product.SKU = sku;
    product.MRP = this.state.MRP;
    product.RetailPrice = this.state.retailPrice;
    product.StoreSpecificPrices.map((l) => {
      l.MRP = this.state.MRP;
      l.RetailPrice = this.state.retailPrice;
    });

    let key = await this.props.addNewProduct(product);
    await this.props.pushProductToRedux({ ...product, key: key });
    await this.props.poupulateSaleSubCategories();
    await this.props.poupulateSaleCategories();
    if (this.state.productAutoGenerateSKU) {
      let autoGenerateSKURunningNo =
        Number(this.props.registration.registration.AutoGenerateSKURunningNo) +
        1;
      await this.props.updateRegistrationGeneralDetails(
        this.props.registration.registration.key,
        { AutoGenerateSKURunningNo: autoGenerateSKURunningNo }
      );
      this.props.getRegistration(this.props.registration.registration.key);
    }
    this.props.getToastr("Product Saved Successfully");
    setTimeout(
      function() {
        this.props.onSave({ ...product, key: key });
      }.bind(this),
      2000
    );
  }

  loadProductBrands(brandID) {
    this.props
      .getProductBrands(actionTypes, this.props.user.user.RegistrationID)
      .then((serachResult) => {
        this.setState({
          brandList: serachResult.searchResult,
          brandID: brandID,
        });
      });
  }

  onFromPriceChange(index, value) {
    let taxPriceRange = this.state.taxPriceRange;
    taxPriceRange[index].FromPrice = value;
    taxPriceRange[index].FromPriceErrMsg = "";
    this.setState({ taxPriceRange: taxPriceRange });
  }

  onToPriceChange(index, value) {
    let taxPriceRange = this.state.taxPriceRange;
    taxPriceRange[index].ToPrice = value;
    taxPriceRange[index].ToPriceErrMsg = "";
    this.setState({ taxPriceRange: taxPriceRange });
  }

  onPriceBasedTaxChange(index, evt) {
    let taxPriceRange = this.state.taxPriceRange;
    taxPriceRange[index].TaxID = evt.target.value;
    taxPriceRange[index].TaxGroup =
      evt.target.options[evt.target.selectedIndex].text;
    taxPriceRange[index].TaxErrMsg = "";
    this.setState({ taxPriceRange: taxPriceRange });
  }

  async onBarCodeChange(value) {
    this.setState({ barCode: value });
    if (value.length > 5) {
      //check if found in global catalog
      let result = await searchPincodeGlobalCatalog(value, "");
      // console.log("result", JSON.stringify(result));
      if (result && result.length > 0) {
        let gcprods = result.filter((f) => f.Code === value);
        if (gcprods.length > 0) {
          if (gcprods.length === 1) {
            let productName =
              gcprods[0].Name +
              " " +
              gcprods[0].Quantity +
              " " +
              gcprods[0].UOM;
            this.setState({
              mrpError: "",
              nameError: "",
              barcodeError: "",
              categoryError: "",
              gcProductName: productName,
              pincodeProductID: gcprods[0].hasOwnProperty("PincodeProductID")
                ? gcprods[0].PincodeProductID
                : "",
              showRemoveBarcodeButton: true,
              barCode: gcprods[0].Code,
              name: productName,
              MRP: gcprods[0].MRP,
              customField1: gcprods[0].Code,
              customField2: "GC",
              customField3: gcprods[0].Type,
              categoryID: "",
              category: gcprods[0].Category,
              subCategoryID: "",
              subCategory: gcprods[0].Subcategory,
              brandID: "",
              brand: gcprods[0].Brand,
              gc: true,
              images: [
                {
                  ImageName: gcprods[0].Code,
                  ImagePath: gcprods[0].Image,
                  ImageSize: 0,
                },
              ],
            });
          } else {
            //now ask for selection
            this.setState({
              customField2: "GCS",
              showGCLookup: true,
              showGCLookupType: "2",
              showGCLookupText: value,
            });
          }
        }
      }
    }
  }

  renderGeneralDetails() {
    return (
      <PageSection title="General Details">
        <div className="row">
          <div className="col-md-12">
            {this.state.customField2 === "GC" && (
              <div
                className="badge green "
                style={{ marginRight: "10px", marginBottom: "10px" }}
              >
                Found in Global Catalogue
              </div>
            )}
            {this.state.customField2 === "NGC" && (
              <div
                className="badge orange-red"
                style={{ marginRight: "10px", marginBottom: "10px" }}
              >
                Not found in Global Catalogue
              </div>
            )}
            {this.state.customField4 !== "" && (
              <div
                className="badge red"
                style={{ marginRight: "10px", marginBottom: "10px" }}
              >
                Catalogue Issue: {this.state.customField4}, Remarks:{" "}
                {this.state.customField5}
              </div>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <Input
              type="text"
              label="Name"
              onChange={(value) => {
                this.setState({ name: value, nameError: "" });
              }}
              value={this.state.name}
              error={this.state.nameError}
              disabled={
                this.state.gc &&
                this.state.customField4 === "" &&
                this.state.GCIssue === ""
              }
            />
            <Input
              type="text"
              label="SKU"
              disabled={
                this.state.productAutoGenerateSKU ||
                (this.state.key && this.state.key !== "")
              }
              onChange={(value) => this.setState({ sku: value, skuError: "" })}
              value={this.state.sku}
              error={this.state.skuError}
            />
            <Input
              type="text"
              label="Barcode (EAN, ISBN, UPC, GTIN, etc.)"
              value={this.state.barCode}
              onChange={(value) => {
                this.onBarCodeChange(value);
              }}
              disabled={this.state.gc}
              error={this.state.barcodeError}
              showRemoveButton={this.state.showRemoveBarcodeButton}
              showButton={!this.state.gc}
              onShowButtonClick={() => {
                this.setState({ showGCLookup: true });
              }}
              showButtonText={"Catalogue Search"}
              onRemove={() => {
                this.setState({
                  showRemoveBarcodeButton: true,
                  barCode: "",
                  name: "",
                  MRP: "",
                  customField1: "",
                  customField2: "",
                  customField3: "",
                  categoryID: "",
                  category: "",
                  subCategoryID: "",
                  subCategory: "",
                  brandID: "",
                  brand: "",
                  gc: false,
                  images: [],
                  barcodeError: "",
                });
              }}
            />
            {this.state.pincodeProductID !== "" && (
              <Input
                type="text"
                label="PSIN"
                value={this.state.pincodeProductID}
                disabled={true}
              />
            )}
            {this.state.gc && this.state.customField4 === "" && (
              <Input
                label="Catalogue Issue"
                type="select"
                onChange={(value) => {
                  if (value === "") {
                    this.setState({
                      name: this.state.gcProductName,
                      GCIssue: value,
                    });
                  } else {
                    this.setState({ GCIssue: value });
                  }
                }}
                value={this.state.GCIssue}
              >
                <option value={""}>Select</option>
                <option value={"Wrong grammage/weight/volume "}>
                  {"Wrong grammage/weight/volume "}
                </option>
                <option value={"Wrong Product"}>{"Wrong Product"}</option>
                <option value={"Offer Pack/Combo"}>{"Offer Pack/Combo"}</option>
                <option value={"Wrong Variant"}>{"Wrong Variant"}</option>
              </Input>
              // </div>
            )}
            {this.state.gc && this.state.customField4 === "" && (
              // <div className="col-md-2">
              <Input
                type="text"
                label="Catalogue Issue Remarks"
                onChange={(value) => this.setState({ GCIssueRemarks: value })}
                value={this.state.GCIssueRemarks}
              />
              // </div>
            )}
          </div>
          <div className="col-md-8">
            <div className="pincode-product-images">
              {this.state.images.map((image, index) => (
                <div className="pincode-product-image">
                  <div className="pincode-product-image-img">
                    <img src={image.ImagePath} alt="Attachment" />
                  </div>
                  <div className="pincode-product-image-remove">
                    <a
                      onClick={() => this.onRemoveImage(index)}
                      className="btn btn-default btn-md pull-right"
                    >
                      <i className="fa fa-trash"></i>
                    </a>
                  </div>
                </div>
              ))}
              <div className="pincode-product-image-upload">
                <Dropzone onDrop={(files) => this.onDrop(files)}>
                  <div>
                    Try dropping some files here, or click to select files to
                    upload.
                  </div>
                </Dropzone>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            {this.state.gc && (
              <Input
                type="text"
                label="Category"
                disabled={this.state.gc}
                value={this.state.category}
              />
            )}
            {!this.state.gc && (
              <div
                className={
                  this.state.categoryError
                    ? "form-group has-error"
                    : "form-group "
                }
              >
                <label>Category</label>
                <div className="input-group">
                  <select
                    className="form-control"
                    value={this.state.categoryID}
                    onChange={this.onCategoryChange}
                    disabled={this.state.gc}
                  >
                    <option value="">Select category</option>
                    {this.props.config.ProductCategories.filter(
                      (e) => e.ParentID === "" && !e.IsDeleted
                    ).map((category) => (
                      <option value={category.key}>{category.Name}</option>
                    ))}
                  </select>

                  <div className="input-group-btn">
                    <>
                      <button
                        type="button"
                        className="btn btn-default btn-md hidden-xs"
                        onClick={() =>
                          this.setState({
                            isShowingModalAddCategory: true,
                            mobile: false,
                            categoryOrSubCategory: actionTypes.PRODUCT_CATEGORY,
                          })
                        }
                      >
                        <i className="fa fa-plus"></i>
                      </button>
                      <button
                        type="button"
                        className="btn btn-default btn-md hidden-md hidden-lg"
                        onClick={() =>
                          this.setState({
                            isShowingModalAddCategory: true,
                            mobile: true,
                            categoryOrSubCategory: actionTypes.PRODUCT_CATEGORY,
                          })
                        }
                      >
                        <i className="fa fa-plus"></i>
                      </button>
                    </>
                  </div>
                </div>
                {this.state.categoryError && (
                  <span className="help-block">Please select category</span>
                )}
              </div>
            )}
          </div>
          <div className="col-md-4">
            {this.state.gc && (
              <Input
                type="text"
                label="Sub Category"
                disabled={this.state.gc}
                value={this.state.subCategory}
              />
            )}
            {!this.state.gc && (
              <div className="form-group">
                <label>Sub Category</label>
                <div className="input-group">
                  <select
                    className="form-control"
                    value={this.state.subCategoryID}
                    onChange={this.onSubCategoryChange}
                  >
                    <option value="">Select sub category</option>
                    {this.props.config.ProductSubCategories.filter(
                      (e) =>
                        e.ParentID !== "" &&
                        e.ParentID === this.state.categoryID &&
                        !e.IsDeleted &&
                        e.Name.toString().trim() !== ""
                    ).map((subCategory, index) => (
                      <option value={subCategory.key}>
                        {subCategory.Name}
                      </option>
                    ))}
                  </select>
                  <div className="input-group-btn">
                    <button
                      type="button"
                      className="btn btn-default btn-md"
                      disabled={this.state.categoryID === "" ? true : false}
                      onClick={() =>
                        this.setState({
                          isShowingModalAddCategory: true,
                          categoryOrSubCategory:
                            actionTypes.PRODUCT_SUB_CATEGORY,
                        })
                      }
                    >
                      <i className="fa fa-plus"></i>{" "}
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="col-md-4">
            {this.state.gc && (
              <Input
                type="text"
                label="Brand"
                disabled={this.state.gc}
                value={this.state.brand}
              />
            )}
            {!this.state.gc && (
              <div className="form-group">
                <label>Brand</label>
                <div className="input-group">
                  <select
                    className="form-control"
                    value={this.state.brandID}
                    onChange={this.onBrandChange}
                  >
                    <option value="">Select brand</option>
                    {this.props.config.ProductBrands.filter(
                      (e) => !e.IsDeleted
                    ).map((brand, index) => (
                      <option value={brand.key}>{brand.Brand}</option>
                    ))}
                  </select>
                  <div className="input-group-btn">
                    <button
                      type="button"
                      className="btn btn-default btn-md"
                      onClick={() =>
                        this.setState({ isShowingModalAddBrand: true })
                      }
                    >
                      <i className="fa fa-plus"></i>{" "}
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>

          {this.state.productTypeID === 3 && (
            <div className="col-md-3">
              <Input
                type="number"
                label="Service Time"
                onChange={(value) => this.setState({ serviceDuration: value })}
                value={this.state.serviceDuration}
              />
            </div>
          )}
        </div>
      </PageSection>
    );
  }

  renderPriceDetails() {
    return (
      <PageSection title="Price & Taxes">
        <div className="row">
          <div className="col-md-2">
            <Input
              type="number"
              label="Supply Price"
              value={this.state.supplyPrice}
              onChange={(value) => {
                this.setState({ supplyPrice: Number(value) });
              }}
              error={this.state.supplyPriceError}
            />
          </div>
          <div className="col-md-2">
            <Input
              type="number"
              label="MSP"
              value={this.state.MSP}
              onChange={(value) => {
                this.setState({ MSP: Number(value) });
              }}
              error={this.state.mspError}
            />
          </div>
          <div className="col-md-2">
            <Input
              type="number"
              label="Retail Price"
              onChange={(value) =>
                this.setState({
                  retailPrice: Number(value),
                  discountAmount:
                    (Number(value) * Number(this.state.discountPercentage)) /
                    100,
                  priceError: "",
                })
              }
              value={this.state.retailPrice}
              error={this.state.priceError}
            />
          </div>
          <div className="col-md-2 col-xs-4">
            <Input
              type="number"
              label="Discount %"
              onChange={(value) => {
                this.setState({
                  discountAmount:
                    (this.state.retailPrice * Number(value)) / 100,
                  discountPercentage: Number(value),
                });
              }}
              value={this.state.discountPercentage}
            />
          </div>
          <div className="col-md-2 col-xs-8">
            <Input
              type="number"
              label="Discount Amount"
              onChange={(value) => {
                this.setState({
                  discountPercentage: Number(
                    (
                      (Number(value) * 100) /
                      Number(this.state.retailPrice)
                    ).toFixed(3)
                  ),
                  discountAmount: Number(value),
                });
              }}
              value={this.state.discountAmount}
            />
          </div>
          <div className="col-md-2">
            <Input
              type="number"
              label="MRP"
              value={this.state.MRP}
              onChange={(value) => {
                this.setState({ MRP: Number(value), mrpError: "" });
              }}
              error={this.state.mrpError}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-2">
            <div className="form-group">
              <label>Charge taxes on this product</label>
              <div className="input-group">
                <Switch
                  checked={this.state.chargeTaxOnPoduct}
                  onChange={() => {
                    this.setState({
                      chargeTaxOnPoduct: !this.state.chargeTaxOnPoduct,
                      taxInclusive: true,
                    });
                  }}
                />
              </div>
            </div>
          </div>
          {this.state.chargeTaxOnPoduct && (
            <div className="col-md-2">
              <div className="form-group">
                <label>Tax inclusive (Sales)</label>
                <div className="input-group">
                  <Switch
                    checked={this.state.taxInclusive}
                    onChange={() => {
                      this.setState({ taxInclusive: !this.state.taxInclusive });
                    }}
                  />
                </div>
              </div>
            </div>
          )}
          {this.state.chargeTaxOnPoduct && (
            <div className="col-md-2">
              <div className="form-group">
                <label>Tax inclusive (Purchase)</label>
                <div className="input-group">
                  <Switch
                    checked={this.state.taxInclusivePurchase}
                    onChange={() => {
                      this.setState({
                        taxInclusivePurchase: !this.state.taxInclusivePurchase,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
        {this.state.chargeTaxOnPoduct && (
          <div className="row">
            <div className="col-md-2">
              <div className="form-group">
                <label>HSN/SAC Code</label>
                <input
                  type="text"
                  className="form-control"
                  onChange={(evt) =>
                    this.setState({ HSNSACCode: evt.target.value })
                  }
                  value={this.state.HSNSACCode}
                  placeholder="HSN/SAC code"
                />
              </div>
            </div>

            <div className="col-md-2">
              <div className="form-group">
                <label>Tax </label>
                <select
                  className="form-control"
                  value={this.state.taxID}
                  onChange={this.onTaxChange}
                >
                  <option value="">Select tax</option>
                  {this.props.config.ProductTaxes.filter(
                    (e) => !e.IsDeleted
                  ).map((t) => (
                    <option value={t.key}>{t.TaxGroup}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        )}
      </PageSection>
    );
  }

  renderInventoryDetails() {
    return (
      <React.Fragment>
        {(this.state.productTypeID === 1 || this.state.productTypeID === 2) && (
          <PageSection title="Inventory">
            <div className="row">
              <div className="col-md-2">
                <div className="form-group">
                  <label>Type</label>
                  <select
                    className="form-control"
                    value={this.state.inventoryType}
                    disabled={
                      this.state.key && this.state.key !== "" ? true : false
                    }
                    onChange={(evt) =>
                      this.setState({
                        inventoryType: evt.target.value,
                        uom: "",
                        secondaryUOMs: [],
                        masterUOMs: [],
                        conversion: 0,
                        uniqueLabel: "",
                        pruchaseUOM: "",
                        salesUOM: "",
                        secondaryUOMValue: "",
                        secondaryUOMError: "",
                        conversionError: "",
                        attributes: [],
                        attribute: "",
                        attributeValue: "",
                        attributeValues: [],
                        selectedAttribute: null,
                        variants: [],
                        autogenerateSKU: false,
                        batchTrackingBatchNoRequired: false,
                        batchTrackingMfgDateRequired: false,
                        batchTrackingMRPRequired: false,
                        batchTrackingExpDateRequired: false,
                      })
                    }
                  >
                    {this.state.inventoryTypeOptions.map((m) => (
                      <option value={m.value}>{m.value}</option>
                    ))}
                  </select>
                </div>
              </div>
              {this.state.inventoryType.toLowerCase() === "unique no" && (
                <div className="col-md-2">
                  <div className="form-group">
                    <label>Unique Label</label>
                    <input
                      type="text"
                      className="form-control"
                      onChange={(evt) =>
                        this.setState({ uniqueLabel: evt.target.value })
                      }
                      value={this.state.uniqueLabel}
                    />
                  </div>
                </div>
              )}
            </div>
            {this.state.inventoryType.toLowerCase() === "batch tracking" && (
              <div className="row">
                <div className="col-md-2">
                  <div className="form-group">
                    {/* <input type="checkbox" checked={this.state.batchTrackingBatchNoRequired} onChange={() => this.setState({ batchTrackingBatchNoRequired: !this.state.batchTrackingBatchNoRequired })} /> */}
                    <label>Batch No</label>
                    <div className="input-group">
                      <Switch
                        checked={this.state.batchTrackingBatchNoRequired}
                        onChange={() => {
                          this.setState({
                            batchTrackingBatchNoRequired: !this.state
                              .batchTrackingBatchNoRequired,
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="form-group">
                    <label>MRP</label>
                    <div className="input-group">
                      <Switch
                        checked={this.state.batchTrackingMRPRequired}
                        onChange={() => {
                          this.setState({
                            batchTrackingMRPRequired: !this.state
                              .batchTrackingMRPRequired,
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="form-group">
                    <label>Mfg Date</label>
                    <div className="input-group">
                      <Switch
                        checked={this.state.batchTrackingMfgDateRequired}
                        onChange={() => {
                          this.setState({
                            batchTrackingMfgDateRequired: !this.state
                              .batchTrackingMfgDateRequired,
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="form-group">
                    <label>Exp Date</label>
                    <div className="input-group">
                      <Switch
                        checked={this.state.batchTrackingExpDateRequired}
                        onChange={() => {
                          this.setState({
                            batchTrackingExpDateRequired: !this.state
                              .batchTrackingExpDateRequired,
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
            {this.state.inventoryType.toLowerCase() === "batch tracking" && (
              <div className="row">
                {(this.state.batchTrackingMfgDateRequired ||
                  this.state.batchTrackingExpDateRequired) && (
                  <div>
                    <div className="col-md-2">
                      <div className="form-group">
                        <label>Date Format</label>
                        <select
                          className="form-control"
                          value={this.state.batchTrackingDateFormat}
                          onChange={(evt) =>
                            this.setState({
                              batchTrackingDateFormat: evt.target.value,
                            })
                          }
                        >
                          <option value="mm/yy">mm/yy</option>
                          <option value="mmm/yy">mmm/yy</option>
                          <option value="mmm/yyyy">mmm/yyyy</option>
                          <option value="dd/mm/yy">dd/mm/yy</option>
                          <option value="dd/mmm/yyyy">dd/mmm/yyyy</option>
                          <option value="mm-yy">mm-yy</option>
                          <option value="mmm-yy">mmm-yy</option>
                          <option value="mmm-yyyy">mmm-yyyy</option>
                          <option value="dd-mm-yy">dd-mm-yy</option>
                          <option value="dd-mmm-yyyy">dd-mmm-yyyy</option>
                        </select>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
            <div className="row">
              <div className="col-md-6">
                <table className="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th>Store</th>
                      <th>Min Qty</th>
                      <th>Max Qty</th>
                      <th>Reorder Qty</th>
                      {/* {!this.state.key &&
                        this.state.inventoryType === "FIFO" && (
                          <th>InStock Qty</th>
                        )} */}
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.storeSpecificQtys.map((s, index) => (
                      <tr>
                        <td>{s.StoreName}</td>
                        <td>
                          <input
                            type="number"
                            className="form-control"
                            onChange={(evt) => {
                              let storeSpecificQtys = this.state
                                .storeSpecificQtys;
                              storeSpecificQtys[index].MinQty = Number(
                                evt.target.value
                              );
                              this.setState({
                                storeSpecificQtys: storeSpecificQtys,
                              });
                            }}
                            disabled={
                              this.state.stores.filter(
                                (e) => e.key === s.StoreID
                              ).length === 0
                            }
                            value={s.MinQty}
                          />
                        </td>
                        <td>
                          <input
                            type="number"
                            className="form-control"
                            onChange={(evt) => {
                              let storeSpecificQtys = this.state
                                .storeSpecificQtys;
                              storeSpecificQtys[index].MaxQty = Number(
                                evt.target.value
                              );
                              this.setState({
                                storeSpecificQtys: storeSpecificQtys,
                              });
                            }}
                            disabled={
                              this.state.stores.filter(
                                (e) => e.key === s.StoreID
                              ).length === 0
                            }
                            value={s.MaxQty}
                          />
                        </td>
                        <td>
                          <input
                            type="number"
                            className="form-control"
                            onChange={(evt) => {
                              let storeSpecificQtys = this.state
                                .storeSpecificQtys;
                              storeSpecificQtys[index].ReorderQty = Number(
                                evt.target.value
                              );
                              this.setState({
                                storeSpecificQtys: storeSpecificQtys,
                              });
                            }}
                            disabled={
                              this.state.stores.filter(
                                (e) => e.key === s.StoreID
                              ).length === 0
                            }
                            value={s.ReorderQty}
                          />
                        </td>
                        {/* {!this.state.key &&
                          this.state.inventoryType === "FIFO" && (
                            <td>
                              <input
                                type="number"
                                className="form-control"
                                onChange={(evt) => {
                                  let storeSpecificQtys = this.state
                                    .storeSpecificQtys;
                                  storeSpecificQtys[index].InStockQty = Number(
                                    evt.target.value
                                  );
                                  this.setState({
                                    storeSpecificQtys: storeSpecificQtys,
                                  });
                                }}
                                disabled={
                                  this.state.stores.filter(
                                    (e) => e.key === s.StoreID
                                  ).length === 0
                                }
                                value={s.InStockQty}
                              />
                            </td>
                          )} */}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </PageSection>
        )}
      </React.Fragment>
    );
  }

  renderAdditonalSettings() {
    return (
      <PageSection title="Additional Settings">
        <div className="row">
          {/* <div className="col-md-3"> */}
          <div className="col-md-2">
            <div className="form-group">
              <label>Favourite&nbsp;</label>
              <span
                className="fa fa-info-circle"
                data-tip
                data-for="favouriteToolTip"
              ></span>
              <ReactTooltip
                id="favouriteToolTip"
                type="dark"
                place="top"
                effect="solid"
              >
                <span>
                  Fast moving product or popular products that can be accessed
                  easily during billing under favourites option
                </span>
              </ReactTooltip>
              <div className="input-group">
                <Switch
                  checked={this.state.favourite}
                  onChange={() => {
                    this.setState({ favourite: !this.state.favourite });
                  }}
                />
              </div>
            </div>
          </div>
          <div className="col-md-2">
            <div className="form-group">
              <label>Allow price edit&nbsp;</label>
              <span
                className="fa fa-info-circle"
                data-tip
                data-for="allowPriceEditToolTip"
              ></span>
              <ReactTooltip
                id="allowPriceEditToolTip"
                type="dark"
                place="top"
                effect="solid"
              >
                <span>
                  User will be able to change retail price / selling price at
                  the time of billing
                </span>
              </ReactTooltip>
              <div className="input-group">
                <Switch
                  checked={this.state.allowPriceEdit}
                  onChange={() => {
                    this.setState({
                      allowPriceEdit: !this.state.allowPriceEdit,
                    });
                  }}
                />
              </div>
            </div>
          </div>
          <div className="col-md-2">
            <div className="form-group">
              <label>Discountable&nbsp;</label>
              <span
                className="fa fa-info-circle"
                data-tip
                data-for="discountableToolTip"
              ></span>
              <ReactTooltip
                id="discountableToolTip"
                type="dark"
                place="top"
                effect="solid"
              >
                <span>
                  Invoice level dicounts, line item level discounts, membership
                  discounts, offer dicounts can be applied on this product at
                  the time of billing. <br />
                  If this opton is not enabled then no discount can be applied
                  on this product.{" "}
                </span>
              </ReactTooltip>
              <div className="input-group">
                <Switch
                  checked={this.state.allowDiscount}
                  onChange={() => {
                    this.setState({
                      allowDiscount: !this.state.allowDiscount,
                    });
                  }}
                />
              </div>
            </div>
          </div>
          <div className="col-md-2">
            <div className="form-group">
              <label>Allow tax change&nbsp;</label>
              <span
                className="fa fa-info-circle"
                data-tip
                data-for="allowTaxChange"
              ></span>
              <ReactTooltip
                id="allowTaxChange"
                type="dark"
                place="top"
                effect="solid"
              >
                <span>
                  Tax can be changed by the user at the time of billing{" "}
                </span>
              </ReactTooltip>
              <div className="input-group">
                <Switch
                  checked={this.state.allowTaxChange}
                  onChange={() => {
                    this.setState({
                      allowTaxChange: !this.state.allowTaxChange,
                    });
                  }}
                />
              </div>
            </div>
          </div>
          <div className="col-md-2">
            <div className="form-group">
              <label>Prompt for qty at the time billing&nbsp;</label>
              <span
                className="fa fa-info-circle"
                data-tip
                data-for="promptQtyToolTip"
              ></span>
              <ReactTooltip
                id="promptQtyToolTip"
                type="dark"
                place="top"
                effect="solid"
              >
                <span>
                  When this product is selected at the time of billing a poup
                  screen with option to change qty will be displayed{" "}
                </span>
              </ReactTooltip>
              <div className="input-group">
                <Switch
                  checked={this.state.qtyPrompt}
                  onChange={() => {
                    this.setState({ qtyPrompt: !this.state.qtyPrompt });
                  }}
                />
              </div>
            </div>
          </div>
          <div className="col-md-2">
            <Input
              type="number"
              label="Sequence No"
              value={this.state.sequenceNo}
              onChange={(value) => this.setState({ sequenceNo: value })}
            />
          </div>
        </div>
        <div className="row">
          {/* <div className="col-md-3"> */}
          {(this.state.productTypeID === 1 ||
            this.state.productTypeID === 2) && (
            <div className="col-md-2">
              <div className="form-group">
                <label>Repack/Produce&nbsp;</label>
                <span
                  className="fa fa-info-circle"
                  data-tip
                  data-for="repackProduceToolTip"
                ></span>
                <ReactTooltip
                  id="repackProduceToolTip"
                  type="dark"
                  place="top"
                  effect="solid"
                >
                  <span>
                    Please select this option if this product is repacked or
                    prodcued by you. <br />
                    E.g, packing loose sugar into packs of 1kg.
                  </span>
                </ReactTooltip>
                <div className="input-group">
                  <Switch
                    checked={this.state.repackProduce}
                    onChange={() => {
                      this.setState({
                        repackProduce: !this.state.repackProduce,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          )}
          {this.state.productTypeID !== 4 && this.state.productTypeID !== 5 && (
            <div className="col-md-2">
              <div className="form-group">
                <label>BOM&nbsp;</label>
                <span
                  className="fa fa-info-circle"
                  data-tip
                  data-for="bomToolTip"
                ></span>
                <ReactTooltip
                  id="bomToolTip"
                  type="dark"
                  place="top"
                  effect="solid"
                >
                  <span>
                    Enable bill of materials (BOM) option to specify raw
                    materials required to prepare/service this product. <br />
                    E.g specify ingredients required to prepare pizza or
                    consumables used for facial
                  </span>
                </ReactTooltip>
                <div className="input-group">
                  <Switch
                    checked={this.state.bom}
                    onChange={() => {
                      this.setState({ bom: !this.state.bom });
                    }}
                  />
                </div>
              </div>
            </div>
          )}
          {this.state.bom && (
            <div className="col-md-2">
              <div className="form-group">
                <label>Manually specify product consumption&nbsp;</label>
                <span
                  className="fa fa-info-circle"
                  data-tip
                  data-for="productConsumptionToolTip"
                ></span>
                <ReactTooltip
                  id="productConsumptionToolTip"
                  type="dark"
                  place="top"
                  effect="solid"
                >
                  <span>
                    Enable this option if the raw materials used and qty used
                    are know only at the time of billing. <br />
                    E.g color, brand and qty of for hair color tubes may vary
                    from client to client.
                  </span>
                </ReactTooltip>
                <div className="input-group">
                  <Switch
                    checked={this.state.bomManual}
                    onChange={() => {
                      this.setState({ bomManual: !this.state.bomManual });
                    }}
                  />
                </div>
              </div>
            </div>
          )}
          {this.state.productTypeID === 1 && (
            <div className="col-md-2">
              <div className="form-group">
                <label>Can be used as consumable&nbsp;</label>
                <span
                  className="fa fa-info-circle"
                  data-tip
                  data-for="consumableToolTip"
                ></span>
                <ReactTooltip
                  id="consumableToolTip"
                  type="dark"
                  place="top"
                  effect="solid"
                >
                  <span>
                    Enable this option if this product can be used as raw
                    material/consumable for other product/services.
                  </span>
                </ReactTooltip>
                <div className="input-group">
                  <Switch
                    checked={this.state.canBeUsedAsConsumable}
                    onChange={() => {
                      this.setState({
                        canBeUsedAsConsumable: !this.state
                          .canBeUsedAsConsumable,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          )}
          <div className="col-md-3">
            <div className="form-group">
              <label>Show as additional charges in bill print &nbsp;</label>
              <div className="input-group">
                <Switch
                  checked={this.state.showAsAdditionalCharges}
                  onChange={() => {
                    this.setState({
                      showAsAdditionalCharges: !this.state
                        .showAsAdditionalCharges,
                    });
                  }}
                />
              </div>
            </div>
          </div>
          {/* </div> */}
        </div>
        {this.renderAuditTrail()}
      </PageSection>
    );
  }

  renderImages() {
    return (
      <PageSection title="Images">
        <div className="row">
          <div className="col-md-3">
            <Dropzone onDrop={(files) => this.onDrop(files)}>
              <div>
                Try dropping some files here, or click to select files to
                upload.
              </div>
            </Dropzone>
          </div>
          <div className="col-md-9">
            <ul className="mailbox-attachments clearfix">
              {this.state.images.map((image, index) => (
                <li>
                  {/* {image.ImagePath} */}
                  <span className="mailbox-attachment-icon has-img">
                    <img src={image.ImagePath} alt="Attachment" />
                  </span>
                  <div className="mailbox-attachment-info">
                    <span className="mailbox-attachment-name">
                      {image.ImageName}
                    </span>
                    <span className="mailbox-attachment-size">
                      {Math.round(image.ImageSize / 1024)} KB
                      <a
                        onClick={() => this.onRemoveImage(index)}
                        className="btn btn-default btn-xs pull-right"
                      >
                        <i className="fa fa-trash"></i>
                      </a>
                    </span>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </PageSection>
    );
  }

  renderAuditTrail() {
    return (
      <div className="row">
        <div className="col-md-3">
          {this.state.key && (
            <AuditTrail
              {...this.props}
              module={constants.MODULE_PRODUCT}
              parentKey={this.state.key}
            />
          )}
        </div>
        <div className="col-md-3">
          {this.state.key && (
            <PincodeAPIlogs {...this.props} productID={this.state.key} />
          )}
        </div>
      </div>
    );
  }

  formControls() {
    return (
      <React.Fragment>
        {this.renderGeneralDetails()}

        {this.renderPriceDetails()}

        {this.renderInventoryDetails()}

        {this.renderAdditonalSettings()}

        {/* {this.renderImages()} */}
      </React.Fragment>
    );
  }

  render() {
    return (
      <React.Fragment>
        {!this.props.newMRP && (
          <Page
            // title={this.props.match.params.key ? "Edit Product" : "New Product"}
            title={this.state.key ? "Edit Product" : "New Product"}
            showSaveButton={!this.state.IsDeleted}
            showCancelButton={true}
            processing={this.state.saving}
            onClick={(action) => {
              if (action === "cancel") {
                this.onCancel();
              } else if (action === "save") {
                this.onSaveProduct();
              }
            }}
          >
            {this.formControls()}
          </Page>
        )}

        {this.props.newMRP && (
          <Window
            header="New MRP"
            width={"400px"}
            modal={false}
            processing={this.state.saving}
            onClose={this.props.onClose}
            onButtonClick={(buttonType) => {
              if (buttonType.toLowerCase() === "save") {
                this.onSaveNewMRP();
              } else {
                this.props.onClose();
              }
            }}
            buttons={[
              { Type: "secondary", Text: "Cancel", Align: "left" },
              { Type: "primary", Text: "Save", Align: "right" },
            ]}
          >
            <div className="row">
              <div className="col-md-12">
                <Input
                  type="text"
                  label="SKU"
                  disabled={this.state.productAutoGenerateSKU}
                  onChange={(value) =>
                    this.setState({ sku: value, skuError: "" })
                  }
                  value={this.state.sku}
                  error={this.state.skuError}
                />
              </div>
              <div className="col-md-12">
                <Input
                  type="number"
                  label="MRP"
                  onChange={(value) => {
                    this.setState({ MRP: value, mrpError: "" });
                  }}
                  value={this.state.MRP}
                  error={this.state.mrpError}
                />
              </div>
              <div className="col-md-12">
                <Input
                  type="number"
                  label="Retail Price"
                  onChange={(value) => {
                    this.setState({ retailPrice: value, priceError: "" });
                  }}
                  value={this.state.retailPrice}
                  error={this.state.priceError}
                />
              </div>
            </div>
          </Window>
        )}

        {this.state.isShowingModalAddCategory && (
          <AddEditProductCategory
            {...this.props}
            // mobile={this.props.mobile}
            showWindow={true}
            type={this.state.categoryOrSubCategory}
            productCategory={null}
            parentID={this.state.categoryID}
            parent={this.state.category}
            onSave={(key, name) => {
              if (
                this.state.categoryOrSubCategory ===
                actionTypes.PRODUCT_CATEGORY
              ) {
                this.setState({
                  categoryID: key,
                  category: name,
                  isShowingModalAddCategory: false,
                });
              } else {
                this.setState({
                  subCategoryID: key,
                  subCategory: name,
                  isShowingModalAddCategory: false,
                });
              }
            }}
            onClose={() => this.setState({ isShowingModalAddCategory: false })}
          />
        )}

        {this.state.isShowingModalAddBrand && (
          <AddEditProductBrand
            {...this.props}
            // mobile={this.props.mobile}
            showWindow={true}
            onSave={(key, name) => {
              //reload brands
              // this.props.getProductBrands(this.props.user.user.RegistrationID)
              this.setState({
                isShowingModalAddBrand: false,
                brand: name,
                brandID: key,
              });
            }}
            onClose={() => this.setState({ isShowingModalAddBrand: false })}
          />
        )}

        {this.state.showGCLookup && (
          <GCLookup
            GCLookupType={this.state.showGCLookupType}
            GCLookupText={this.state.showGCLookupText}
            onClose={() => this.setState({ showGCLookup: false })}
            onSelect={(product) => {
              let productName =
                product.Name + " " + product.Quantity + " " + product.UOM;
              this.setState({
                mrpError: "",
                nameError: "",
                barcodeError: "",
                categoryError: "",
                gcProductName: productName,
                showRemoveBarcodeButton: true,
                barCode: product.Code,
                name: productName,
                MRP: product.MRP,
                customField1: product.Code,
                customField2: "GC",
                customField3: product.Type,
                pincodeProductID: product.hasOwnProperty("PincodeProductID")
                  ? product.PincodeProductID
                  : "",
                categoryID: "",
                category: product.Category,
                subCategoryID: "",
                subCategory: product.Subcategory,
                brandID: "",
                brand: product.Brand,
                gc: true,
                images: [
                  {
                    ImageName: product.Code,
                    ImagePath: product.Image,
                    ImageSize: 0,
                  },
                ],
                showGCLookup: false,
              });
            }}
          ></GCLookup>
        )}
      </React.Fragment>
    );
  }
}

export default withRouter(ProductPincode);
