import axios from "axios";
import firebase from "../../firebase";
import { filter } from "lodash";

export async function getPincodeProducts(
  from,
  size,
  registrationid,
  storeid,
  searchText,
  category,
  brand,
  outOfStockManual = false,
  outOfStockCancellation = false
) {
  let searchParameters = {
    from: from,
    size: size,
    track_total_hits: true,
    query: {
      bool: {
        must: [
          {
            match: {
              RegistrationID: registrationid,
            },
          },
          {
            match: {
              IsDeleted: false,
            },
          },
          {
            match: {
              "StoreSpecificPrices.StoreID.keyword": storeid,
            },
          },
        ],
        should: [
          {
            wildcard: { Name: "*" + searchText.trim().toLowerCase() + "*" },
          },
          {
            wildcard: { SKU: "*" + searchText.trim().toLowerCase() + "*" },
          },
          {
            wildcard: { BarCode: "*" + searchText.trim().toLowerCase() + "*" },
          },
        ],
        minimum_should_match: 1,
      },
    },
    sort: [
      {
        ActionOn: {
          order: "desc",
        },
      },
    ],
  };

  // filter: [
  //   {
  //     regexp: { Name: "@" + searchText.trim().toLowerCase() + ".*" },
  //   },
  //   {
  //     regexp: { SKU: "@" + searchText.trim().toLowerCase() + ".*" },
  //   },
  //   {
  //     regexp: { BarCode: "@" + searchText.trim().toLowerCase() + ".*" },
  //   },
  // ],
  // if (sku !== "") {
  //   searchParameters.query.bool.must.push({
  //     regexp: { SKU: "@" + sku.trim().toLowerCase() + ".*" },
  //   });
  // }
  // if (barCode !== "") {
  //   searchParameters.query.bool.must.push({
  //     regexp: { BarCode: "@" + barCode.trim().toLowerCase() + ".*" },
  //   });
  // }
  // if (productName !== "") {
  //   searchParameters.query.bool.must.push({
  //     regexp: { Name: "@" + productName.trim().toLowerCase() + ".*" },
  //   });
  // }
  if (category !== "") {
    searchParameters.query.bool.must.push({
      match: {
        "Category.keyword": category,
      },
    });
  }
  if (brand !== "") {
    searchParameters.query.bool.must.push({
      match: {
        "Brand.keyword": brand,
      },
    });
  }
  if (outOfStockManual) {
    searchParameters.query.bool.must.push(
      {
        term: {
          "StoreSpecificPrices.OutOfStock": true,
        },
      },
      {
        bool: {
          should: [
            {
              term: {
                "StoreSpecificPrices.OutOfStockReason.keyword": "",
              },
            },
            {
              bool: {
                must_not: [
                  {
                    exists: {
                      field: "StoreSpecificPrices.OutOfStockReason",
                    },
                  },
                ],
              },
            },
          ],
          minimum_should_match: 1,
        },
      }
    );
  }
  if (outOfStockCancellation) {
    searchParameters.query.bool.must.push(
      {
        term: {
          "StoreSpecificPrices.OutOfStock": true,
        },
      },
      {
        exists: {
          field: "StoreSpecificPrices.OutOfStockReason",
        },
      }
    );
    searchParameters.query.bool.must_not = [
      {
        term: {
          "StoreSpecificPrices.OutOfStockReason.keyword": "",
        },
      },
    ];
  }

  console.log("searchParameters", JSON.stringify(searchParameters));
  let addMessage = firebase.functions().httpsCallable("getProducts");
  return addMessage({ text: searchParameters }).then(function(result) {
    let sanitizedMessage = result.data.text;
    let products = [];
    sanitizedMessage.hits.hits.map((data, index) => {
      // products.push({ key: data._id, ...data._source });
      let product = data._source;
      product.key = data._id;
      products.push(product);
    });
    return {
      totalItemsCount: sanitizedMessage.hits.total.value,
      searchResult: products,
    };
  });
}
export async function getPincodeBrandForRegistration(registrationid) {
  let searchParameters = {
    query: {
      bool: {
        must: [
          {
            match: {
              RegistrationID: registrationid,
            },
          },
          {
            match: {
              IsDeleted: false,
            },
          },
        ],
      },
    },
    aggs: {
      brands: {
        terms: {
          field: "Brand.keyword",
          size: 10000,
        },
      },
    },
  };

  let addMessage = firebase.functions().httpsCallable("getProducts");
  return addMessage({ text: searchParameters }).then(function(result) {
    let sanitizedMessage = result.data.text;
    let brands = [];
    sanitizedMessage.aggregations.brands.buckets.map((b) => {
      brands.push({
        Name: b.key,
      });
    });
    return brands;
  });
}

export async function getPincodeCategoryForRegistration(registrationid) {
  let searchParameters = {
    query: {
      bool: {
        must: [
          {
            match: {
              RegistrationID: registrationid,
            },
          },
          {
            match: {
              IsDeleted: false,
            },
          },
        ],
      },
    },
    aggs: {
      categories: {
        terms: {
          field: "Category.keyword",
          size: 10000,
        },
      },
    },
  };

  let addMessage = firebase.functions().httpsCallable("getProducts");
  return addMessage({ text: searchParameters }).then(function(result) {
    let sanitizedMessage = result.data.text;
    let categories = [];
    sanitizedMessage.aggregations.categories.buckets.map((categoryBucket) => {
      categories.push({
        Name: categoryBucket.key,
      });
    });
    return categories;
  });
}

export async function searchPincodeGlobalCatalog(
  code,
  name,
  type = "",
  searchtext = ""
) {
  try {
    const res = await axios.post(
      "https://us-central1-posible-app-prod.cloudfunctions.net/apiSearchPincodeGlobalCatalog",
      { code: code, name: name, type: type, searchtext: searchtext }
    );
    console.log(JSON.stringify(res.data));
    return res.data.pincodeCatalog;
  } catch (err) {
    console.log("searchPincodeGlobalCatalog", err);
  }
}

export async function getPincodePopularProducts() {
  try {
    const res = await axios.post(
      "https://us-central1-posible-app-prod.cloudfunctions.net/apiGetPincodePopularItems"
    );
    console.log(JSON.stringify(res.data));
    return res.data.pincodePopularItems;
  } catch (err) {
    console.log("getPincodePopularProducts", err);
  }
}

export async function getMerchantProductsThatArePartOfPopularProducts(
  registrationID,
  codes
) {
  try {
    let searchQuery = {
      size: 10000,
      query: {
        bool: {
          filter: [
            {
              match: {
                RegistrationID: registrationID,
              },
            },
            {
              terms: {
                SKU: codes,
              },
            },
            {
              match: {
                IsDeleted: false,
              },
            },
          ],
        },
      },
    };

    let addMessage = firebase.functions().httpsCallable("getProducts");

    return addMessage({ text: searchQuery }).then(function(result) {
      const products = [];
      let sanitizedMessage = result.data.text;
      sanitizedMessage.hits.hits.map((data, index) => {
        products.push({ key: data._id, ...data._source });
      });
      return products;
    });
  } catch (err) {
    console.log("getMerchantProductsThatArePartOfPopularProducts", err);
  }
}

// export async function getPincodeGlobalCatalogSubCategories() {
//   try {
//     console.log("getPincodeGlobalCatalogSubCategories");
//     const res = await axios.post(
//       "https://us-central1-posible-app-prod.cloudfunctions.net/apiGetPincodeGlobalCatalogSubCategories"
//     );
//     console.log(JSON.stringify(res.data));
//     return res.data.subCategories;
//   } catch (err) {
//     console.log("getPincodeGlobalCatalogSubCategories", err);
//   }
// }

// export async function getPincodeGlobalCatalogProductTypes() {
//   try {
//     console.log("getPincodeGlobalCatalogProductType");
//     const res = await axios.post(
//       "https://us-central1-posible-app-prod.cloudfunctions.net/apiGetPincodeGlobalCatalogProductTypes"
//     );
//     console.log(JSON.stringify(res.data));
//     return res.data.productTypes;
//   } catch (err) {
//     console.log("getPincodeGlobalCatalogProductTypes", err);
//   }
// }

// export async function getPincodeGlobalCatalogBrands() {
//   try {
//     console.log("getPincodeGlobalCatalogBrands");
//     const res = await axios.post(
//       "https://us-central1-posible-app-prod.cloudfunctions.net/apiGetPincodeGlobalCatalogBrands"
//     );
//     console.log(JSON.stringify(res.data));
//     return res.data.productTypes;
//   } catch (err) {
//     console.log("getPincodeGlobalCatalogBrands", err);
//   }
// }

// export async function getPincodeGlobalCatalogUOM() {
//   try {
//     console.log("getPincodeGlobalCatalogUOM");
//     const res = await axios.post(
//       "https://us-central1-posible-app-prod.cloudfunctions.net/apiGetPincodeGlobalCatalogUOM"
//     );
//     console.log(JSON.stringify(res.data));
//     return res.data.uoms;
//   } catch (err) {
//     console.log("getPincodeGlobalCatalogUOM", err);
//   }
// }
