import React, { Component } from "react";
import Icon from "../../2/Controls/Icon";

class WidgetPincodeDashboard extends Component {
  constructor({ stores, fromDate, toDate, widget }) {
    super();
    this.state = {
      totalSales: 0,
      offlineSales: 0,
      onlineSales: 0,
      totalTransactions: 0,
      offlineTransactions: 0,
      onlineTransactions: 0,
      stores: stores,
      fromDate: fromDate,
      toDate: toDate,
      widget: widget,
      totalCustomers: 0,
      totalExpenses: 0,
      totalPaid: 0,
      offlinePaid: 0,
      onlinePaid: 0,
    };
    // console.log('widget',widget)
  }

  async componentDidMount() {
    await this.loadData(
      this.props.stores,
      this.props.fromDate,
      this.props.toDate
    );
  }
  //
  async loadData(stores, fromDate, toDate) {
    let totalSales = 0;
    let offlineSales = 0;
    let onlineSales = 0;
    let totalTransactions = 0;
    let onlineTransactions = 0;
    let offlineTransactions = 0;
    let totalCustomers = 0;
    let offlineCustomers = 0;
    let onlineCustomers = 0;
    let totalPaid = 0;
    let offlinePaid = 0;
    let onlinePaid = 0;
    let result = await this.props.getSalesDashboardPincode(
      this.props.user.user.RegistrationID,
      stores,
      "",
      "",
      fromDate,
      toDate
    );
    console.log("result", result);
    for (let i = 0; i < result.length; i++) {
      let doc = result[i];
      totalPaid = Number(totalPaid) + Number(doc.total_paid_sales.value);
      totalSales = Number(totalSales) + Number(doc.total_sales.value);
      totalTransactions =
        Number(totalTransactions) + Number(doc.total_transactions.value);
      totalCustomers =
        Number(totalCustomers) + Number(doc.total_customers.value);
      if (
        doc.key ===
        this.props.registration.registration.PinocdeOrderTransactionTypeID
      ) {
        onlineSales = Number(onlineSales) + Number(doc.total_sales.value);
        onlineTransactions =
          Number(onlineTransactions) + Number(doc.total_transactions.value);
        onlineCustomers =
          Number(onlineCustomers) + Number(doc.total_customers.value);
        onlinePaid = Number(onlinePaid) + Number(doc.total_paid_sales.value);
      } else {
        offlineSales = Number(offlineSales) + Number(doc.total_sales.value);
        offlineTransactions =
          Number(offlineTransactions) + Number(doc.total_transactions.value);
        offlineCustomers =
          Number(offlineCustomers) + Number(doc.total_customers.value);
        offlinePaid = Number(offlinePaid) + Number(doc.total_paid_sales.value);
      }
    }

    let result1 = await this.props.getExpensesforSalesDashboard(
      this.props.user.user.RegistrationID,
      stores,
      "",
      fromDate,
      toDate
    );

    this.setState({
      totalSales: totalSales,
      offlineSales: offlineSales,
      onlineSales: onlineSales,
      totalTransactions: totalTransactions,
      onlineTransactions: onlineTransactions,
      offlineTransactions: offlineTransactions,
      totalCustomers: totalCustomers,
      offlineCustomers: offlineCustomers,
      onlineCustomers: onlineCustomers,
      totalExpenses: result1.searchResult.total_expenses,
      totalPaid: totalPaid,
      onlinePaid: onlinePaid,
      offlinePaid: offlinePaid,
    });
  }

  componentWillReceiveProps({ stores, fromDate, toDate }) {
    this.loadData(stores, fromDate, toDate);
  }

  render() {
    return (
      <>
        <div className="col-md-3">
          <div className="info-box info-blue">
            <div className="info-box-icon ">
              <Icon type="sales" />
            </div>
            <div className="info-box-content">
              <div className="info-box-text">Sales</div>
              <div className="info-box-number">
                {Number(this.state.totalPaid).toFixed(0)}
              </div>
              <div className="info-box-number-subtext">
                Offline {Number(this.state.offlinePaid).toFixed(0)}
              </div>
              <div className="info-box-number-subtext">
                Online {Number(this.state.onlinePaid).toFixed(0)}
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="info-box info-red">
            <div className="info-box-icon ">
              <Icon type="list" />
            </div>
            <div className="info-box-content">
              <div className="info-box-text">Transactions</div>
              <div className="info-box-number">
                {Number(this.state.totalTransactions).toFixed(0)}{" "}
                <div className="info-box-number-subtext">
                  Offline {Number(this.state.offlineTransactions).toFixed(0)}
                </div>
                <div className="info-box-number-subtext">
                  Online {Number(this.state.onlineTransactions).toFixed(0)}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="info-box info-yellow">
            <div className="info-box-icon ">
              <Icon type="customer" />
            </div>
            <div className="info-box-content">
              <div className="info-box-text">Customers</div>
              <div className="info-box-number">
                {Number(this.state.totalCustomers).toFixed(0)}
              </div>
              <div className="info-box-number-subtext">
                <br></br>
              </div>
              <div className="info-box-number-subtext">
                <br></br>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="info-box info-green">
            <div className="info-box-icon ">
              <Icon type="expense" />
            </div>
            <div className="info-box-content">
              <div className="info-box-text">Expenses</div>
              <div className="info-box-number">{this.state.totalExpenses}</div>
              <div className="info-box-number-subtext">
                <br></br>
              </div>
              <div className="info-box-number-subtext">
                <br></br>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default WidgetPincodeDashboard;
